
.icon-sim_container {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  .icon-sim {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }
}
