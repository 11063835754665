@import './typography.scss';

/* iOS Cordova Tweak */
.device-cordova.device-ios {
  height: 100vh;
}

/* Custom color theme properties */
:root ,
.md{
  --f7-tabbar-link-letter-spacing: 0;
  --f7-toolbar-height: 60px;
  --f7-list-item-title-line-height: 35px;
  --f7-table-body-font-size: 11px;
  --f7-calendar-today-bg-color: #ccc;
  --f7-text-color: #101010;
  --f7-theme-color: #fff;
  --f7-theme-color-rgb: 255, 255, 255;
  --f7-theme-color-shade: #3c3b3b;
  --f7-theme-color-tint: #2e2e2e;
  --f7-input-outline-padding-horizontal: 15px;
  --f7-input-outline-height: 45px;
  --f7-list-item-header-text-color: #8e8e93;
  --f7-searchbar-height: 52px;
  --f7-navbar-height: 45px;
  --f7-grid-gap: 10px;
  --f7-list-media-item-padding-vertical : 14px;
  --f7-block-margin-vertical: 10px;
  --f7-button-height: 45px;
  --f7-radio-border-width: 1px;
  --f7-radio-inactive-color: #ccc;
  --f7-radio-active-color: rgb(1,160,211);
  --f7-input-height: 20px;
  --f7-tabbar-link-text-transform: inherit;
  --f7-checkbox-border-width: 1px;
  --f7-checkbox-inactive-color: #ccc;
  --f7-checkbox-active-color: rgb(1,160,211);
  --f7-list-item-title-font-size: 14px;
  --f7-block-title-font-size: 16px;
  --f7-block-title-text-color: #000;
  --f7-dialog-button-text-color: #333;
  --f7-list-item-after-font-size: 12px;
  --f7-toggle-height: 24px;
  --f7-toggle-width: 40px;
  --f7-list-item-min-height: 58px;
}

/* Invert navigation bars to fill style */
:root,
:root.theme-light,
:root .theme-light,
:root .md {
  --f7-bars-bg-color: var(--f7-theme-color);
  --f7-bars-bg-color-rgb: var(--f7-theme-color-rgb);
  --f7-bars-translucent-opacity: 0.9;
  --f7-bars-text-color: #000;
  --f7-bars-link-color: #000;
  --f7-navbar-subtitle-text-color: rgba(255,255,255,0.85);
  --f7-bars-border-color: transparent;
  --f7-tabbar-link-active-color: rgb(1,160,211);
  //--f7-tabbar-link-inactive-color:rgb(204,204,204);
  --f7-sheet-border-color: transparent;
  --f7-tabbar-link-active-border-color: #fff;
}
.user-settings,
.sim-connectivity, 
.dashboard .toolbar-top {
  --f7-tabbar-link-active-color: #000;
}
 
.md  .user-settings  .tabbar:not(.toolbar-bottom) .tab-link, 
.md .user-settings  .tabbar-labels .tab-link,
.md  .sim-connectivity  .tabbar:not(.toolbar-bottom) .tab-link, 
.md .sim-connectivity  .tabbar-labels .tab-link,
.md  .dashboard  .tabbar:not(.toolbar-bottom) .tab-link, 
.md .dashboard  .tabbar-labels .tab-link  {
  padding-bottom: 10px;
}

.tabbar .link, .tabbar-labels .link {
  line-height: 1.1
}

.md .user-settings  .toolbar .tab-link-active.link:after,
.md .sim-connectivity  .toolbar .tab-link-active.link:after,  
  .md .dashboard  .toolbar.toolbar-top .tab-link-active.link:after{
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #000;
  bottom: 0;
}
.appbar,
.navbar,
.toolbar,
.subnavbar,
.calendar-header,
.calendar-footer {
  --f7-touch-ripple-color: var(--f7-touch-ripple-white);
  --f7-link-highlight-color: var(--f7-link-highlight-white);
  --f7-link-touch-ripple-color: var(--f7-touch-ripple-white);
  --f7-button-text-color: #fff;
  --f7-button-pressed-bg-color: rgba(255,255,255,0.1);
}
.navbar-large-transparent,
.navbar-large.navbar-transparent {
  --f7-navbar-large-title-text-color: #000;

  --r: 26;
  --g: 26;
  --b: 26;
  --progress: var(--f7-navbar-large-collapse-progress);
  --f7-bars-link-color: rgb(
    calc(var(--r) + (255 - var(--r)) * var(--progress)),
    calc(var(--g) + (255 - var(--g)) * var(--progress)),
    calc(var(--b) + (255 - var(--b)) * var(--progress))
  );
}
.theme-dark .navbar-large-transparent,
.theme-dark .navbar-large.navbar-transparent {
  --f7-navbar-large-title-text-color: #fff;
}
.md label.item-radio input[type='radio']:checked ~ .icon-radio:after, .md label.item-radio input[type='radio']:checked ~ * .icon-radio:after, .md .radio input[type='radio']:checked ~ .icon-radio:after {
   transform: scale(1.3);
}
.calendar-day-selected .calendar-day-number {
  background-color: #333;
}
// .md .navbar a.link.popup-close {
//   color: transparent;
// }
// .md .navbar a.link.popup-close:before {
//   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'%3E%3Cpath d='m512 40l-40-40-216 216-216-216-40 40 216 216-216 216 40 40 216-216 216 216 40-40-216-216z'%3E%3C/path%3E%3C/svg%3E");
// }

// .md .navbar a.link:before {
//   content: '';
 
//   height: 15px;
//   width: 15px;
//   left: 44px;
 
//  top: inherit;  
//   position: absolute;
//   background-image: radial-gradient(circle at center, var(--f7-link-highlight-color) 66%, rgba(255, 255, 255, 0) 66%);
//   background-repeat: no-repeat;
 
//   background-size: 100% 100%;
//   opacity: 1; 
//   pointer-events: inherit;  
//   transition-duration: 300ms;
 
// }

.toggle-list-item .item-after {
  transform:translate(0px, 24%);
}
.toggle-list-item .item-inner span {
  line-height: 34px;
}
 
.ios{
  --f7-actions-button-font-size: 16px;
  --f7-panel-backdrop-bg-color: rgba(0, 0, 0, 0.3);
}
.md .toggle input[type='checkbox']:checked + .toggle-icon {
    background: rgba(var(--f7-theme-color-rgb), 1); 
}
.md .toggle input[type='checkbox']:checked + .toggle-icon:after {
  background: #fff;
}
.toolbar-top:before, .ios .toolbar-top-ios:before, .md .toolbar-top-md:before, .aurora .toolbar-top-aurora:before, .toolbar-bottom:after, .ios .toolbar-bottom-ios:after, .md .toolbar-bottom-md:after, .aurora .toolbar-bottom-aurora:after, .navbar-bg:before {
  background: none;
}
.ios .icon-back:after, .ios .icon-prev:after {
  display: none;
}
.md .item-input .item-media {
  align-self: center;
}

.item-input .item-inner {
  justify-content: center;
}
.md .toggle-icon:after {
  height: calc(var(--f7-toggle-height) - 4px);
  width: calc(var(--f7-toggle-height) - 4px);
  top: 2px;
  box-shadow: none;
  border-radius: var(--f7-toggle-height);
  left: 2px;
}
// .link i + span, .link i + i, .link span + i, .link span + span {
//   display: none;
// }

.link i + span, .link i + i, .link span + i, .link span + span {
  margin-left: 0;
  font-size: 12px;
}
.md .toggle input[type='checkbox']:checked + .toggle-icon:after {
  transform: translateX(calc(var(--f7-toggle-width) - var(--f7-toggle-height)));
 }
 

.media-list .item-link .item-title-row:before, li.media-item .item-link .item-title-row:before {
  top: 20%;
}

.md .navbar label.icon-only {
  min-width: 0;
  flex-shrink: 0;
  width: 48px;
}

 .md .navbar label.link {
   padding: 0 12px;
   min-width: 48px;
 }

 .navbar label.link {
   display: flex;
   justify-content: flex-start;
   line-height: var(--f7-navbar-link-line-height, var(--f7-navbar-height));
   height: var(--f7-navbar-link-height, var(--f7-navbar-height));
 }

 .navbar label {
   color: var(--f7-navbar-link-color, var(--f7-bars-link-color, var(--f7-theme-color)));
 }
/* Your app custom styles here */
.toolbar.custom-toolbar{
  --f7-toolbar-height: 60px;
  background: var(--f7-page-bg-color);
}
.toolbar-font-size-12{
  --f7-toolbar-font-size: 12px!important;
}
/*.toolbar.custom-toolbar:before{
  background-color: var(--f7-list-border-color);
}*/

.total-summary {
  --f7-actions-button-height: 25px;
}

.total-summary .actions-button-text  {
  padding-bottom: 16px;
}
.actions-group:after {
  display: none;
}
.actions-title {
  line-height: 1;
    color: #888;
    font-size: 12px;
}
.custom-toolbar~.page-content{
  --f7-toolbar-height: 60px;
}
.md .item-input-error-message, .md .item-input-info, .md .input-error-message, .md .input-info{
  white-space: normal;
}
.bigger-item-text.list .item-text{
  --f7-list-item-text-max-lines: 5;
}
.list .item-header{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions-button.col-50:first-child{
  border-radius: var(--f7-actions-border-radius) 0 0 0;
}
.actions-button.col-50:last-child{
  border-radius: 0 0 var(--f7-actions-border-radius) 0 ;
}
 
.ios-translucent-modals .actions-button.disabled{
  opacity: 0.85 !important;
}
.sim-menu {
  overflow-y: auto;
  height: calc(100% - (var(--f7-page-toolbar-bottom-offset) + var(--f7-page-navbar-offset) + 80px))
 }

  
.actions-button {
  background: none;
  height: auto;
}
 

.actions-button-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions-button-text {
  text-align: center;
  color: #000000;
  margin-left: 0 !important;
  padding-top: 16px;
  border-radius: 5px;
}
.actions-button-text {
  background-color: #fff;
}
.actions-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
 

.actions-group:not(.customer-menu):not(.total-data) .actions-button:nth-child(odd) {
  padding-right: 5px;
}

.actions-group:not(.customer-menu):not(.total-data) .actions-button:nth-child(even) {
  padding-left: 5px;
}

.md .actions-modal{
  background: #fff;
}
.ios .media-list .item-media, .ios li.media-item .item-media{
  min-width: 40px;
}
.custom-smart-select-wrapper{
  .item-title{
    width: 100%;
    vertical-align: top;
    flex-shrink: 0;
    font-size: var(--f7-label-font-size);
    font-weight: var(--f7-label-font-weight);
    line-height: var(--f7-label-line-height);
    color: var(--f7-label-text-color);
    transition-duration: 200ms;
    transition-property: transform, color;
  }
  .item-after{
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    padding-left: 0;
    color: var(--f7-input-text-color);
    font-size: var(--f7-input-font-size);
    line-height: var(--f7-input-height);
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    min-height: 36px;
  }

  .item-title-row {
    flex-direction: column;
  }
}
.ios .custom-smart-select-wrapper  .item-inner{
  padding-bottom: 0;
}
.custom-smart-select-wrapper .item-after {
  min-height: inherit;
}
.md .custom-smart-select-wrapper .item-after:after {
  content: '';
  position: absolute;
  background-color: var(--f7-list-item-border-color);
  display: block;
  z-index: 15;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  transform-origin: 50% 100%;
  transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
  display: none;
}
.md .bigger-media .item-checkbox .icon-checkbox{
  --f7-checkbox-extra-margin: 20px;
}
.ios .bigger-media .item-checkbox .icon-checkbox{
  --f7-checkbox-extra-margin: 5px;
}

.sim-list .toolbar-top ~ *,
.ios .sim-list .toolbar-top-ios ~ *,
.md .sim-list .toolbar-top-md ~ *,
.aurora .sim-list .toolbar-top-aurora ~ *,
.user-list .toolbar-top ~ *,
.ios .user-list .toolbar-top-ios ~ *,
.md .user-list .toolbar-top-md ~ *,
.aurora .user-list .toolbar-top-aurora ~ * {
  --f7-page-toolbar-top-offset: 85px;
}

.user-settings .toolbar-top ~ *,
.ios .user-settings .toolbar-top-ios ~ *,
.md .user-settings .toolbar-top-md ~ *,
.aurora .user-settings .toolbar-top-aurora ~ *,
.sim-connectivity .toolbar-top ~ *,
.ios .sim-connectivity .toolbar-top-ios ~ *,
.md .sim-connectivity .toolbar-top-md ~ *,
.aurora .sim-connectivity .toolbar-top-aurora ~ *,
.dashboard .toolbar-top ~ *,
.ios .dashboard .toolbar-top-ios ~ *,
.md .dashboard .toolbar-top-md ~ *,
.aurora .dashboard .toolbar-top-aurora ~ *{
  --f7-page-toolbar-top-offset: 75px;
}
.toolbar-inner-no-padding .toolbar-inner{
  padding: 0!important;
}
.toolbar-top .toolbar-inner {
  flex-direction: column;
}

.calendar .toolbar-top .toolbar-inner {
  flex-direction: row;
}
.sim-list .toolbar-top,
.user-list .toolbar-top{
  --f7-toolbar-height: 85px;
}
.user-settings .toolbar-top,
.sim-connectivity .toolbar-top,
.dashboard .toolbar-top {
  --f7-toolbar-height: 75px;
}

.toolbar-inner > .title {
    align-self: baseline;
    padding-left: 15px;
    font-weight: bold;
    font-size: 22px;
}
.ios .dialog .list ul{
  --f7-list-bg-color: #f0f2f5;
}
.list ul{
  --f7-list-bg-color: #f0f2f5;
}
.list li:not(.login-list-input) {
  margin: 10px 0 5px 0;
  background-color: #fff;
  border-radius: 5px;
}


.progressbar-top{
  position: fixed;
  top: calc(var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) + var(--f7-page-subnavbar-offset, 0px) + var(--f7-page-searchbar-offset, 0px) + var(--f7-page-content-extra-padding-top, 0px));
}

.md .actions-button-media {
  min-width: 28px;
}

/*LOGIN SCREEN*/
.login-screen-content{
  --f7-list-bg-color: trasparent;
  background: url('../static/images/bg.jpg') no-repeat top center;
  background-size: cover;
  height: 100%!important;
}
.login-screen-content .logo{
  margin-top: 72px;
  margin-bottom: 72px;
}
.login-screen-content .logo img{
  max-width: 220px;
  width:100%;
}
.login-screen-content .list input{
  /*  background: rgba(255,255,255,.9)!important;*/
}
.login-screen-content .list input{
 // --f7-input-text-color: #fff;
}
.md .item-input:not(.item-input-outline) .item-input-wrap:after, .md .input:not(.input-outline):after {
  display: none;
}
.list .item-input .item-media + .item-inner {
  --f7-list-item-media-margin: 0;
}
.disabled {
  --f7-label-text-color: #888888;
  opacity: 1 !important;
}
 .list .disabled input{
    --f7-input-text-color: #888888;
 }
.login-screen-content .list input::-webkit-input-placeholder{
  color: rgb(153,153,153);
}
.login-screen-content .list input:-ms-input-placeholder{
  color: rgb(153,153,153);
}
.login-screen-content .list input::placeholder{
  color: rgb(153,153,153);
}
.md .login-screen-content .item-input-focused .item-label, .md .login-screen-content .item-input-focused .item-floating-label {
  --f7-label-focused-text-color: #fff;
}

.md .login-screen-content .item-input-focused:not(.item-input-outline) .item-input-wrap:after, .md .login-screen-content .input-focused:not(.input-outline):after {
  --f7-input-focused-border-color: #fff;
}
.login-screen-content .input-clear-button.password-clear-button{
  margin-right: 48px;
}
.password-toggle {
  position: absolute;
  right: 8px;
  cursor: pointer;
  transition: all .3s;

  margin-top: calc(-1 * 25px / 2);
  top: calc(var(--f7-input-height)/ 2);
}


/*PANEL LEFT*/


.panel-left-list {
  --f7-list-bg-color: #f0f2f5;
}

.panel-left-list li{
  background-color: #fff;
  border-radius: 5px;
  .item-media {
    justify-content: center;
    i {
      display: flex;
    }
  }
}
.panel-left-list li:not(:last-child) {
  margin-bottom: 10px;
}


.bg-header{
 // background-image: url('../static/images/menu-bg.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  background-color: #fff;

  img.logo{
    width: 75%;
  }

  .app-version{
    position: absolute;
    font-size: 10px;
    bottom: 4px;
    right: 6px;
  }

  .user-img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }
}
.card-content-divider {
  width: 50px;
  background: #fff;
  height: 1px;
  margin: auto;
}

.card {
  box-shadow: none;
}

.md .csp-dialog-select.dialog-buttons-vertical .dialog-button + .dialog-button,
.md .csp-dialog-select .dialog-button {
 margin-top: 0;
 font-size: 12px;
}

/*CUSTOM SEARCH*/
.navbar ~ .toolbar-top.additional-toolbar-top,
.navbars ~ * .toolbar-top.additional-toolbar-top,
.navbars ~ .page:not(.no-navbar) .toolbar-top.additional-toolbar-top{
  top: calc(calc(var(--f7-navbar-height) + var(--f7-safe-area-top)) + var(--f7-toolbar-height));
 
}
.toolbar-top {
  border-bottom: 1px solid #dcdcdc;
}
.toolbar-bottom {
  border-top: 1px solid #dcdcdc;
}

.views > .toolbar, .view > .toolbar, .popup > .toolbar {
  position: absolute;
}
.link.back span {
  display: none;
}

.toolbar-top.additional-toolbar-top ~ *{
  --f7-page-toolbar-top-offset: calc(var(--f7-toolbar-height) + var(--f7-toolbar-height));
}
.custom-searchbar{
  --f7-searchbar-input-padding-horizontal: 2px;
  --f7-searchbar-input-extra-padding-left: 0;
  --f7-searchbar-input-extra-padding-right: 50px;

  overflow: hidden;

  .input-clear-button {
    right: 48px;
  }
  .submit-button{
    transition-duration: 100ms;
    position: absolute;
    top: 50%;
    border: none;
    padding: 0;
    margin: 0;
    outline: 0;
    z-index: 31;
    cursor: pointer;
    background: none;
    width: var(--f7-input-clear-button-size);
    height: var(--f7-input-clear-button-size);
    margin-top: calc(-1 * var(--f7-input-clear-button-size) / 2);
    color: var(--f7-input-clear-button-color);
    right: 0;
  }
}
.md .searchbar.custom-searchbar{
  --f7-searchbar-input-extra-padding-right: 94px;

  .input-clear-button {
    right: 48px;
  }
  .submit-button{
    width: 48px;
    height: 48px;
    margin-top: -24px;
    right: 0;

    i{
      width: 48px;
      height: 48px;
      line-height: 52px;
      font-size: 20px;
    }
  }
}
.ios .searchbar.custom-searchbar{
  background: transparent;
  backdrop-filter: none;

  .searchbar-inner{
 
    --f7-searchbar-input-extra-padding-left: 5px;
    --f7-searchbar-inner-padding-right: 0;
    --f7-searchbar-inner-padding-left: 0;
  }
  .input-clear-button{
    right: 40px;
  }
  .submit-button{
    --f7-input-clear-button-size: 32px;

    i{
      width: var(--f7-input-clear-button-size);
      height: var(--f7-input-clear-button-size);
      font-size: 15px;
      line-height: 36px;
    }
  }
}

.searchbar-inner{
  padding: 5px 13px;
}

.searchbar-input-wrap {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.searchbar input[type='text'], .searchbar input[type='search'] {
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}


.searchbar input[type='search']::placeholder {
  font-size: 13px;
  padding-bottom: 4px;
  font-weight: bold;
}


.list {
  .item-content{
    .item-media{
      img{
        width: 100%;
        max-width: 40px;
        border-radius: 50%;
      }
    }
  }
}

/*MAP*/
.leaflet-top.leaflet-right{
  .leaflet-control-layers-toggle{
    background-image: url('../static/images/change-map.svg') !important;
    background-size: 100% 100% !important;
  }
  .leaflet-control-layers:not(.leaflet-control-layers-expanded){
    border-radius: 50%;
  }
}
.map-wrapper{
  width: 100%;
  height: 100%;
}
.marker-data-table{
  td{
    border-bottom: 1px solid #E6E6E6;
    font-size: 13px;
    line-height: 1.2;
    padding: 4px 0;
    vertical-align: text-top;
  }
  tr:last-child td{
    border-bottom: 1px solid transparent;
  }
  .marker-data-caption{
    text-align: left;
    white-space: nowrap;
    color: #818181;
  }
  .marker-data-value{
    text-align: right;
    color: #333;
    font-weight: 600;
    padding-left: 10px;
  }
}




.custom-modal-logo-wrapper{
  text-align: center;
}
.custom-modal-logo{
  max-width: 160px;
  width: 100%;
}
.custom-modal-title{
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  word-break: break-all;
}
.custom-modal-text{
  margin-top: 10px;
  color: #757575;
  font-size: 16px;
}

.list.media-center .item-media i,
.list.media-center .item-media{
  text-align: center;
  margin: 0 auto;
}
.list .item-media{
  position: relative;
}
.status{
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #c1c1c1;
  border: 2px solid #fff;
}
.status-green{
  background-color: #40bfa2 !important;
}
.status-orange{
  background-color: #fdb583 !important;
}
.status-red{
  background-color: #d47980 !important;
}
.status-gray{
  background-color: #cccccc !important;
}
.status-aqua {
  background-color: #2ec7c9 !important;
}
.status-blue {
  background-color: #00a0d3 !important;
}


.customer-icon{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin:0 auto;
  background-color: #333;
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-card{
  --f7-card-margin-vertical: 0;
  --f7-card-margin-horizontal: 0;
  /*--f7-card-header-font-size: 12px;*/
  --f7-card-border-radius: 6px;
  overflow: hidden;
}
.chart {
  width: 100%;
  height: 100%;
}
.dialog-inner .dialog-text {
  max-height: 50vh;
  overflow: auto;
}


 

 


// .md .icon-back, .md .icon-forward, .md .icon-next, .md .icon-prev {
//   height: inherit;
// }

.simstatus-card {
  --f7-list-item-title-line-height: 23px;
  --f7-list-item-min-height: 10px;
  --f7-list-item-padding-vertical: 0px;
  --f7-list-item-title-font-size: 14px;
  --f7-list-font-size: 14px;
  padding: 15px 5px;
  .list li:not(.login-list-input) {
    margin: 0;
  }

  .status {
    position: static;
  }
 
}
.media-list .item-media, li.media-item .item-media {
  align-self: center;
}
 
// .md .icon-back, .md .icon-forward, .md .icon-next, .md .icon-prev {
//   height: 24px;
// }

.navbar a.link{
  line-height: inherit;
}


.summary .actions-button {
  pointer-events: none;
}

@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .ios-translucent-modals .actions-button{
    background-color:#f0f2f5;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: none;
  }
  .ios-translucent-modals .actions-label  {
    background-color:#fff;
  }
}

